import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {User} from '../interfaces/User'
import {createUser as postUser, getUserAll,getUserAllForAdmin} from '../http'

interface UserState {
  getAllUserData: User | null
  getAllUserLoading: boolean
  getAllUserErrors: any
  getAllUserForAdminData: User | null
  getAllUserForAdminLoading: boolean
  getAllUserForAdminErrors: any
  createUserData: User | null
  createLoading: boolean
  createErrors: any
  signOutUserData: any | null
  signOutLoading: boolean
  signOutErrors: any
}

const initialState: UserState = {
  getAllUserData: null,
  getAllUserLoading: false,
  getAllUserErrors: null,
  getAllUserForAdminData: null,
  getAllUserForAdminLoading: false,
  getAllUserForAdminErrors: null,
  createUserData: null,
  createLoading: false,
  createErrors: null,
  signOutUserData: null,
  signOutLoading: false,
  signOutErrors: null
}

export const getAllUserForAdmin = createAsyncThunk(
  'user/getAllUserForAdmin',
  async ({token}: {token: any;}, thunkAPI) => {
    try {      
      const response = await getUserAllForAdmin('user/list', token)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getAllUser = createAsyncThunk(
  'user/getAllUser',
  async ({token}: {token: any;}, thunkAPI) => {
    try {      
      const response = await getUserAll('user/all', token)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createUser = createAsyncThunk(
  'user/createUser',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {body} = data
      const response = await postUser('user/add', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const signOut = createAsyncThunk(
  'user/signOut',
  async ({token}: {token: any}, thunkAPI) => {
    try {      
      const response = await postUser('user/logout', token)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.getAllUserData = action.payload
    },
  },
  extraReducers: (builder) => { 
    builder.addCase(getAllUser.pending, (state, action) => {
      state.getAllUserLoading = true
      state.getAllUserData = null
    })
    builder.addCase(getAllUser.fulfilled, (state, action) => {
      state.getAllUserLoading = false
      state.getAllUserData = action.payload
      state.getAllUserErrors = null
    })
    builder.addCase(getAllUser.rejected, (state, action) => {
      state.getAllUserLoading = false
      state.getAllUserData = null
      state.getAllUserErrors = action.payload
    })   
    builder.addCase(getAllUserForAdmin.pending, (state, action) => {
      state.getAllUserForAdminLoading = true
      state.getAllUserForAdminData = null
    })
    builder.addCase(getAllUserForAdmin.fulfilled, (state, action) => {
      state.getAllUserForAdminLoading = false
      state.getAllUserForAdminData = action.payload
      state.getAllUserForAdminErrors = null
    })
    builder.addCase(getAllUserForAdmin.rejected, (state, action) => {
      state.getAllUserForAdminLoading = false
      state.getAllUserForAdminData = null
      state.getAllUserForAdminErrors = action.payload
    }) 
    builder.addCase(createUser.pending, (state, action) => {
      state.createLoading = true
      state.createUserData = null
    })
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.createLoading = false
      state.createUserData = action.payload
      state.createErrors = null
    })
    builder.addCase(createUser.rejected, (state, action) => {
      state.createLoading = false
      state.createUserData = null
      state.createErrors = action.payload
    })
    builder.addCase(signOut.pending, (state, action) => {
        state.signOutLoading = true;
        state.signOutUserData = null;
        state.signOutErrors = null
    });
    builder.addCase(signOut.fulfilled, (state, action) => {
        state.signOutLoading = false;
        state.signOutUserData = action.payload;
    });
    builder.addCase(signOut.rejected, (state, action) => {
        state.signOutLoading = false;
        state.signOutErrors = action.payload;
        state.signOutUserData = null;
    });
  },
})

export default userSlice.reducer
export const {setUser} = userSlice.actions
