/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {login, googleSignIn} from '../core/_requests'
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {PhoneVerificationDialog} from '../components/PhoneVerificationDialog';
import bcrypt from 'bcryptjs';

const emailRegex = /\S+@\S+\.\S+/;
const phoneRegex = /^[0-9]{10}$/;

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .test('emailOrNumber', 'Invalid email or phone number with isd code', function(value) {
      // Check if input is a valid email address
      if (Yup.string().email().isValidSync(value)) {
        return true;
      }
      // Check if input is a valid phone number
      if (/^\+?\d{12}$/.test(value)) {
        return true;
      }
      // If input is neither a valid email nor a valid phone number, return false
      return false;
    })    
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email or phone number with isd code is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export interface LoginProps {
  email: string
  password: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const searchParams = new URLSearchParams(location.search);
  const clientId: any = "633658650439-qaiupf3kuod0at0aivh52ojfdnqhmaim.apps.googleusercontent.com"
  
  const salt = bcrypt.genSaltSync(10);
  const [isOpen, setIsOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [messageType, setMessageType] = useState<string>('mb-lg-15 alert alert-danger');
  const [referralCode, setReferralCode] = useState<string>(searchParams.get('ref-code') || '');

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handlePhoneVerification = () => {
    setIsOpen(true)
  }

  const handleClose = (value) => {
    if(value){
      setMessageType('mb-lg-15 alert alert-success');
      formik.setStatus('Your phone no is verified! Please sign-in');
    }
    setIsOpen(false)
  }

  const handleLoginSuccess = (response) => {
    // Your logic for handling successful login
  };
  
  const handleLoginFailure = (response) => {
    // Your logic for handling failed login
  };

  const responseMessage = (response) => {
  }

  const errorMessage = (error) => {
  }

  const googleLogin =  useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        // const encodedAuth_Code = Buffer.from(codeResponse?.code).toString('base64');
        const encodedAuth_Code = btoa(codeResponse?.code);
        const {data: auth} = await googleSignIn(encodedAuth_Code, referralCode)
        if (auth?.apierror?.errorCode === 2031) {          
          saveAuth(undefined)       
          setLoading(false)
        } 
        else if (auth?.apierror?.errorCode === 2032 || auth?.apierror?.errorCode === 2033){
          saveAuth(undefined)        
        }
        else if (auth?.apierror?.errorCode === 2034){
          saveAuth(undefined)         
        }
        else {          
          saveAuth(auth)          
          const user = auth
          setCurrentUser(user)                    
        }
      }
      catch(error: any){
        saveAuth(undefined)
      }
    },
    onError: (error) => console.log('Login Failed:', error),
    flow: 'auth-code'
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        //dispatch(GetLogin({ email: "", pwd: "" }))
        // const hashedPassword  = bcrypt.hashSync(values.password, salt);
         
        const {data: auth} = await login(values.email, values.password)
       
        if (auth?.apierror?.errorCode === 2031) {          
          saveAuth(undefined)  
          setMessageType('mb-lg-15 alert alert-danger');
          setStatus(<button type='button' className="btn btn-link" onClick={handlePhoneVerification}> {auth?.apierror?.message} click here!</button>)
          setSubmitting(false)
          setLoading(false)
        } 
        else if (auth?.apierror?.errorCode === 2032 || auth?.apierror?.errorCode === 2033){
          saveAuth(undefined)       
          setMessageType('mb-lg-15 alert alert-danger');   
          setStatus(auth?.apierror?.message)
          setSubmitting(false)
          setLoading(false)
        }
        else if (auth?.apierror?.errorCode === 2034){
          saveAuth(undefined)       
          setMessageType('mb-lg-15 alert alert-danger');   
          setStatus(auth?.apierror?.message)
          setSubmitting(false)
          setLoading(false)
        }
        else {          
          saveAuth(auth)          
          const user = auth
          setCurrentUser(user)
          localStorage.setItem('User', auth)          
        }
      } catch (error) {        
        saveAuth(undefined)
        setMessageType('mb-lg-15 alert alert-danger');
        setStatus('Either username or password incorrect.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })  

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-4'>
          <h1 className='text-dark mb-3'>Sign In to Trade It Lazy</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            New Here?{' '}
            <Link to='/auth/sign-up' className='link-primary fw-bolder'>
              Create an Account
            </Link>          
          </div>
        </div>   
        {formik.status ? (
          <div className={messageType}>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        <div className='fv-row mb-2'>
          <TextField          
            margin='normal'
            type='text'
            label='Email Id / Phone Number'
            placeholder='Please enter Email Id / Phone Number with isd code'
            InputLabelProps={{
              className: 'required fw-bolder'            
            }}
            fullWidth
            className='form-control form-control-lg form-control-solid'            
            autoComplete='email'          
            {...formik.getFieldProps('email')}
          />       
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>       
          <TextField           
            margin='normal'
            size='medium'              
            type={showPassword ? 'text' : 'password'}
            label='Password' 
            placeholder='Please enter Password' 
            className='form-control form-control-lg form-control-solid'               
            fullWidth  
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }} 
            InputLabelProps={{
              className: 'required fw-bolder'            
            }}       
            {...formik.getFieldProps('password')} 
          />        
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>           
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
              >
                Forgot Password ?
              </Link>
            </div>
          </div>       
        </div>
        <div className='text-center'>
          <Button
            type='submit'
            id='kt_sign_in_submit'
            variant='contained'
            color='info'
            fullWidth
            size='large'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>

          <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>

          {/* begin::Google link */}
          {/* <GoogleLogin 
            clientId='633658650439-qaiupf3kuod0at0aivh52ojfdnqhmaim.apps.googleusercontent.com'
            onSuccess={responseMessage}
            onFailure={errorMessage}
            accessType="offline"
            responseType='code'
          /> */}
          {/* <GoogleLogin
            clientId='633658650439-qaiupf3kuod0at0aivh52ojfdnqhmaim.apps.googleusercontent.com'
            res
          /> */}
          <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5' onClick={() => googleLogin()}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
            Continue with Google
          </a>
          {/* end::Google link */}

          {/* begin::Kite link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/kite-logo.svg')}
              className='h-20px me-3'
            />
            Continue with Kite
          </a> */}
          {/* end::Kite link */}

          {/* begin::ICICIDirect link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/icici-direct-logo.png')}
              className='h-20px me-3'
            />
            Continue with ICICIDirect
          </a> */}
          {/* end::ICICIDirect link */}

          {/* begin::Upstrox link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/upstrox-logo.png')}
              className='h-20px me-3'
            />
            Continue with Upstrox
          </a> */}
          {/* end::Upstrox link */}

          {/* begin::Angel One link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/angil-one-logo.png')}
              className='h-20px me-3'
            />
            Continue with Angel One
          </a> */}
          {/* end::Angel One link */}

          {/* begin::5 Paisa link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/5paisa-logo.png')}
              className='h-20px me-3'
            />
            Continue with 5 Paisa
          </a> */}
          {/* end::5 Paisa link */}
          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
              className='h-20px me-3'
            />
            Continue with Facebook
          </a> */}
          {/* end::Google link */}

          {/* begin::Google link */}

          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='h-20px me-3'
            />
            Continue with Apple
          </a> */}
          {/* end::Google link */}
        </div>
        {/* end::Action */}
      </form>
      {isOpen && <PhoneVerificationDialog open={isOpen} id={0} phoneNo={''} onClose={handleClose} signIn={true} />}
    </>
  )
}
